'use strict';

(function() {
  const sessionLifetime = 30 * 60 * 1000; // 30 minutes
  const sessionWarningThreshold = sessionLifetime - 5 * 60 * 1000; // 30 minutes - 5 minutes
  const sessionExpiresThreshold = sessionLifetime + 1000; // 30 minutes + 1 second
  const lsSession = new window.MedimoLocalStorage('medimo:keepalive:session');

  let currentNotification;
  let session; // local mirror of local storage value
  let showingANotificationIsAllowed = true;

  function setCurrentNotification(name) {
    currentNotification = name;
  }

  function showNotification(title, message, buttonText) {
    // Remove focus from the active element, so the user cannot type or click enter when a form has focus
    if (document.activeElement instanceof Element) {
      document.activeElement.blur();
    }

    const wrapper = document.createElement('div');
    const modal = document.createElement('div');
    const heading = document.createElement('h2');
    const intro = document.createElement('p');

    wrapper.id = 'extend_session_wrapper';
    modal.id = 'extend_session';
    heading.innerHTML = title;
    intro.innerHTML = message;

    wrapper.appendChild(modal);
    modal.appendChild(heading);
    modal.appendChild(intro);

    // Add button if we have a buttonText string
    if (typeof buttonText === 'string') {
      const buttonWrap = document.createElement('p');
      const button = document.createElement('button');

      buttonWrap.className = 'button-wrap';
      button.id = 'keepalive-notification-button';
      button.className = 'btn btn-medimo';
      button.innerHTML = buttonText;

      buttonWrap.appendChild(button);
      modal.appendChild(buttonWrap);
    }

    // Add modal to body
    document.body.appendChild(wrapper);
  }

  function handleLogout(type) {
    if (type === 'session') {
      showSessionLogoutNotification();
    } else {
      showTimerLogoutNotification();
    }

    window.medimo.allowNavigationOnUnsavedChanges = true; // app.js checks this value when the user changes pages but he/she has important changes.
    // Mocht de parse for whatever reason failen
    try {
      const logoutUrl = JSON.parse(window.sessionStorage.getItem('data')).current_user.logoutUrl;
      if (logoutUrl) { // Als hij leeg is negeren we deze uiteraard
        window.location.href = logoutUrl;
        return;
      }
    } catch (e) {
      // Default to below
    }
    window.location.href = '/logout?automatic=true';
  }

  function showTimerLogoutNotification() {
    const title = 'Inactiviteit gedetecteerd';
    const message = 'U bent te lang inactief geweest, daarom wordt u doorverwezen naar de beginpagina om opnieuw in te loggen.';
    showNotification(title, message);
  }

  function showSessionLogoutNotification() {
    const title = 'Uitgelogd op een ander scherm';
    const message = 'U bent uitgelogd op een ander scherm, daarom wordt u doorverwezen naar de beginpagina.';
    showNotification(title, message);
  }

  function showPageExpiredNotification() {
    const title = 'Inactiviteit gedetecteerd';
    const message = 'Deze pagina is verlopen. Ververs de pagina of klik hieronder.';
    const buttonText = 'Klik hier om verder te gaan';
    showNotification(title, message, buttonText);

    const button = document.getElementById('keepalive-notification-button');
    button.addEventListener('click', function() {
      window.location.reload();
    });
  }

  function showSessionChangedNotification() {
    const title = 'Nieuwe sessie op ander scherm gedetecteerd';
    const message = 'Deze pagina is verlopen vanwege een<br>nieuwe sessie op een ander scherm.';
    const buttonText = 'Klik hier om verder te gaan';
    showNotification(title, message, buttonText);

    const button = document.getElementById('keepalive-notification-button');
    button.addEventListener('click', function() {
      window.location.href = '/';
    });
  }

  function showPageExpiresTimer() {
    const title = 'Inactiviteit gedetecteerd';
    const message = 'Waarschuwing: Over <span id="session_time_remaining">00:00</span> is deze pagina<br/>niet meer geldig wegens inactiviteit.';
    const buttonText = 'Klik hier om de sessie te verlengen';
    showWarningWithTimer(title, message, buttonText);
  }

  function triggerNotification(name, showCallback, updateCallback) {
    if (notificationHasPriority(currentNotification, name)) {
      // Only build the notification if it is not already showing
      if (currentNotification !== name) {
        removeCurrentNotification();
        setCurrentNotification(name);
        showCallback();
      }

      if (typeof updateCallback === 'function') {
        updateCallback();
      }
    }
  }

  function notificationHasPriority(currentNotification, name) {
    if (!showingANotificationIsAllowed) {
      return false;
    }

    // Decide on priority
    if (isAuthenticatedPage()) {
      switch (currentNotification) {
        case 'logout':
          return false;
        case 'sessionChanged':
          return name === 'logout';
        default:
          return true;
      }
    } else {
      switch (currentNotification) {
        case 'sessionChanged':
          return false;
        case 'pageExpired':
          return name === 'sessionChanged';
        default:
          return true;
      }
    }
  }

  function removeCurrentNotification() {
    const wrap = document.getElementById("extend_session_wrapper");
    if (wrap instanceof Element) {
      wrap.parentNode.removeChild(wrap);

      // Reset currentNotification, because we're not showing a notification now
      setCurrentNotification();
    }
  }

  function showWarningWithTimer(title, message, buttonText) {
    showNotification(title, message, buttonText);

    if (typeof window.Sentry !== "undefined") {
      window.Sentry.addBreadcrumb({
        message: "warning threshold reached, showing dialog",
        category: "keepalive"
      });
    }

    const button = document.getElementById('keepalive-notification-button');
    button.addEventListener("click", function() {
      if (typeof window.Sentry !== "undefined") {
        window.Sentry.addBreadcrumb({
          message: "user clicked extend dialog",
          category: "keepalive"
        });
      }

      // Doing a request will also refresh our session
      // We don't need to remove the notification, as a the
      // request will update the session, and the timer loop
      // will pick this up and remove the notification
      window.ajax.post('/extendsession', {});
    });
  }

  function getRemainingSessionTime() {
    let remaining = sessionExpiresTime() - Date.now();
    if (remaining < 0) {
      return '00:00';
    }
    remaining = new Date(remaining);

    let minutes = remaining.getMinutes();
    if (minutes < 10) {
      minutes = "0" + minutes;
    }

    let seconds = remaining.getSeconds();
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    return minutes + ':' + seconds;
  }

  function handleVisibilityChange() {
    // Window is active
    if (!windowIsActive()) {
      if (typeof window.Sentry !== "undefined") {
        window.Sentry.addBreadcrumb({
          message: "user switched away, tab/window is now paused",
          category: "keepalive"
        });
      }
    // Window is inactive
    } else {
      // IE 11 on win8 does not catch storage events when the window is
      // inactive, so we check for changes when the window becomes active
      // again.
      checkLocalStorageForUpdates();

      if (typeof window.Sentry !== "undefined") {
        window.Sentry.addBreadcrumb({
          message: "user came back, tab/window is now resumed",
          category: "keepalive"
        });
      }
    }
  }

  function windowIsActive() {
    return !document.hidden;
  }

  function timerLoop(warningCallback, expiredCallback) {
    // Don't check the thresholds when the window is not active, so we don't
    // log the user out because a session in an inactive window was expired
    if (!windowIsActive()) {
      return;
    }

    // Check if the session is about to expire
    if (expiresThresholdPassed()) {
      expiredCallback();
    } else if (warningThresholdPassed()) {
      warningCallback();
    } else if (timerNotificationIsShowing()) {
      // When the timer box is shown, but we did not pass any thresholds, the
      // session was updated and we can remove the notification box
      removeCurrentNotification();
    }
  }

  function sessionWarningTime() {
    return session.refreshedAt + sessionWarningThreshold;
  }

  function sessionExpiresTime() {
    if (session === null) {
      startNewSession(isAuthenticatedPage());
    }
    return session.refreshedAt + sessionExpiresThreshold;
  }

  function expiresThresholdPassed() {
    return Date.now() > sessionExpiresTime();
  }

  function warningThresholdPassed() {
    return Date.now() > sessionWarningTime();
  }

  function timerNotificationIsShowing() {
    return currentNotification === 'logoutTimer' || currentNotification === 'pageExpiresTimer';
  }

  function startSessionTimer(warningCallback, expiredCallback) {
    setInterval(function () {
      timerLoop(warningCallback, expiredCallback);
    }, 500);

    // The timers are not checked when the window is inactive and IE 11 on win8
    // does not catch storage events, so we need to handle that when the
    // visibility of the window changes
    document.addEventListener('visibilitychange', handleVisibilityChange, false);
  }

  function updateTimer() {
    const timer = document.getElementById('session_time_remaining');
    if (timer !== null) {
      timer.innerHTML = getRemainingSessionTime();
    }
  }

  function isAuthenticatedPage() {
    return window.$('body').hasClass('logged-in');
  }

  function userJustLoggedIn() {
    if (!isAuthenticatedPage()) {
      return false;
    }
    return session.isAuthenticated !== true;
  }

  function userJustLoggedOut() {
    if (isAuthenticatedPage()) {
      return false;
    }
    return session.isAuthenticated !== false;
  }

  function sessionExpired() {
    if (session.refreshedAt === undefined) {
      return false;
    }
    return session.refreshedAt + sessionLifetime < Date.now();
  }

  function initSessionForTimer() {
    session = {refreshedAt: Date.now()};
  }

  function startNewSession(isAuthenticated) {
    session = {
      id: Date.now(),
      refreshedAt: Date.now(),
      isAuthenticated: isAuthenticated,
    };
    lsSession.set(session);
  }

  function refreshSession() {
    session.refreshedAt = Date.now();
    lsSession.set(session);
  }

  function localStorageWasCleared() {
    return session !== null && lsSession.get() === null;
  }

  function localStorageWasClearedEvent(event) {
    return event.key === null || event.key === ''; // event.key === '' for IE.
  }

  function newSessionWasStarted() {
    if (session === null) {
      startNewSession(isAuthenticatedPage());
    }
    return session.id !== lsSession.get().id;
  }

  function newSessionWasStartedEvent(event) {
    return event.key === lsSession.key() && newSessionWasStarted();
  }

  function sessionWasRefreshed() {
    return session.refreshedAt !== lsSession.refreshedAt;
  }

  function sessionWasRefreshedEvent(event) {
    return event.key === lsSession.key() && sessionWasRefreshed();
  }

  function loadLocalStorage() {
    if (window.MedimoLocalStorage.isSupported()) {
      session = lsSession.get();
    }
  }

  function registerStorageEventHandler() {
    window.addEventListener('storage', handleStorageEvent);
  }

  function handleStorageWasCleared() {
    startNewSession(isAuthenticatedPage());
  }

  function handleNewSessionWasStarted() {
    // Log user out when he/she logs out in a different window
    if (session.isAuthenticated && !lsSession.get().isAuthenticated) {
      handleLogout('session');
    // Otherwise show the session changed notification
    } else {
      triggerNotification('sessionChanged', showSessionChangedNotification);
    }
  }

  function handleSessionWasRefreshed() {
    session = lsSession.get();
  }

  function handleStorageEvent(event) {
    if (localStorageWasClearedEvent(event)) {
      handleStorageWasCleared();
    } else if (newSessionWasStartedEvent(event)) {
      handleNewSessionWasStarted();
    } else if (sessionWasRefreshedEvent(event)) {
      handleSessionWasRefreshed();
    }
  }

  function checkLocalStorageForUpdates() {
    if (localStorageWasCleared()) {
      handleStorageWasCleared();
    } else if (newSessionWasStarted()) {
      handleNewSessionWasStarted();
    } else if (sessionWasRefreshed()) {
      handleSessionWasRefreshed();
    }
  }

  // Initialize keepalive on document ready
  window.$(function() {
    // Make sure no notifications pop up when leaving the page
    window.addEventListener('unload', function () {
      showingANotificationIsAllowed = false;
    });

    if (window.MedimoLocalStorage.isSupported()) {
      // Functionality only for when local storage is supported
      loadLocalStorage();

      if (session === null || userJustLoggedOut() || sessionExpired()) {
        startNewSession(false);
      } else if (userJustLoggedIn()) {
        startNewSession(true);
      }

      registerStorageEventHandler();
    } else {
      // No local storage support, so we init 'session' just for use with the session timer
      initSessionForTimer();
    }

    // Thresholds can work without local storage, with the note that working
    // on multiple screens can make you logout when visiting a logged in screen
    // that has been inactive for more than 'sessionLifetime' minutes.
    refreshSession();

    if (isAuthenticatedPage()) {
      // User loads a page for which he/she needs to be logged in
      startSessionTimer(function () {
        triggerNotification('logoutTimer', showPageExpiresTimer, updateTimer);
      }, function () {
        triggerNotification('logout', handleLogout);
      });
    } else {
      // User loads a page for which he/she does not need to be logged in
      startSessionTimer(function () {
        triggerNotification('pageExpiresTimer', showPageExpiresTimer, updateTimer);
      }, function () {
        triggerNotification('pageExpired', showPageExpiredNotification);
      });
    }

    // refresh session on ajax request
    window.ajax.onComplete((event, xhr) => {
      if (xhr.status !== 401) { // don't refresh when unauthorized
        refreshSession();
      }
    });
    window.axios?.interceptors.response.use((response) => {
      if (response.status !== 401) { // don't refresh when unauthorized
        refreshSession();
      }
      return response;
    });
  });
})();
